import { ref } from 'vue'

function useShiftSelect() {
  const firstClickedIndex = ref(null)
  const selectedItems = ref([])

  function shiftSelect({ data, selected, value, index, getItemValue }) {
    if (!Array.isArray(data)) return

    selectedItems.value = [...selected]
    if (firstClickedIndex.value === null) {
      firstClickedIndex.value = index
      selectedItems.value.push(getItemValue(value) || value)
      return
    }

    const start = Math.min(firstClickedIndex.value, index)
    const end = Math.max(firstClickedIndex.value, index)

    const rangeItems = data.slice(start, end + 1).map(getItemValue)
    selectedItems.value = [...new Set([...selectedItems.value, ...rangeItems])]

    firstClickedIndex.value = null
  }

  function clearIndex() {
    firstClickedIndex.value = null
  }

  return {
    selectedItems,
    shiftSelect,
    clearIndex
  }
}

export { useShiftSelect }
